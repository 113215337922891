








































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { FETCH_REQUIRE_FORM_SHEET, SET_LIST_PAGE } from '../store';
import FormSheetTable from '../components/FormSheetTable.vue';
import MonthPicker from '@/components/form/MonthPicker.vue';
import CompensationStateSelect from '../components/CompensationStateSelect.vue';
import { CompensationList } from '@/modules/compensation/types';
import { ApiResponse } from '@/components/types';
import { SearchableMixin } from '@/views/mixin';
import { routeToLocation } from '@/router';

const CompensationStore = namespace('compensation');

@Component({
  components: {
    CompensationStateSelect,
    FormSheetTable,
    MonthPicker
  }
})
export default class Compensations extends SearchableMixin {
  @CompensationStore.State('list') list!: CompensationList;
  @CompensationStore.Mutation(SET_LIST_PAGE) setPage!: (page: number) => void;
  @CompensationStore.Action(FETCH_REQUIRE_FORM_SHEET) fetch!: () => Promise<ApiResponse>;

  loading: boolean = false;
  rowsPerPage: number = 10;

  get month(): string | undefined {
    return this.$route.query['month'] as string;
  }

  set month(month: string | undefined) {
    if (!month) {
      const { month, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, month } })).catch(() => {});
  }

  get items() {
    return this.list.items
      .filter((compensation) => !this.month || format(compensation.end, 'yyyy-MM') <= this.month);
  }

  async created() {
    this.loading = true;

    await this.fetch();

    this.loading = false;
  }
}
