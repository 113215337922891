













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CompensationStateSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String, default: 'select.compensationType' }) fieldName!: string;

  list = [
    { value: null, text: 'Alle' },
    { value: 'readyToMail', text: 'Offen' },
    { value: 'requireFormSheet', text: 'Formblatt ausstehend' },
    { value: 'mailed', text: 'Versendet' }
  ];

  created() {
    this.$emit('input', this.value);
  }
}
