


























































import { Component, Prop } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import { PaginatableMixin } from '@/views/mixin';
import { Compensation, State } from '@/modules/compensation/types';
import { Pagination } from '@/components/types';
import AddFormSheetDialog from '@/modules/compensation/components/AddFormSheetDialog.vue';
import FormSheetDownloadAction from '@/modules/compensation/components/FormSheetDownloadAction.vue';
import UpdateFormSheetDialog from '@/modules/compensation/components/UpdateFormSheetDialog.vue';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: {
    AppTable,
    UpdateFormSheetDialog,
    FormSheetDownloadAction,
    AddFormSheetDialog
  }
})
export default class FormSheetTable extends PaginatableMixin {
  @Prop({ type: Boolean }) loading?: boolean;
  @Prop({ type: Array }) list!: Compensation[];
  @Prop({ type: Number, default: 10 }) rowsPerPage!: number;
  @Prop({
    type: Function, default: () => {
    }
  }) setPage!: (page: number) => void;
  @Prop({ type: String }) iSearch?: string;

  pagination: Pagination = {};
  urls: any = {};

  formSheet: Compensation | null = null;
  updateFormSheet: Compensation | null = null;


  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'conservateeName' },
      { align: 'right', text: this.$i18n.t('common.attorneyDocket'), value: 'attorneyDocket' },
      { align: 'right', text: this.$i18n.t('label.invoiceNumber'), value: 'invoiceNumber' },
      { align: 'right', text: this.$i18n.t('label.dateRange'), value: 'end' },
      { align: 'right', text: this.$i18n.t('label.formSheet'), value: null, sortable: false },
      { align: 'right', text: this.$i18n.t('common.actions'), value: 'endTrans', sortable: false }
    ];
  }

  get items() {
    return this.list.map(({ begin, end, payed, conservatee, ...compensation }) => ({
      ...compensation,
      conservatee,
      begin,
      end,
      payed,
      beginTrans: format(begin, 'dd.MM.yyyy'),
      endTrans: format(end, 'dd.MM.yyyy'),
      conservateeName: `${conservatee.surname}, ${conservatee.forename}`,
      rowColor: this.rowColor(compensation.state),
      btnColor: this.btnColor(compensation.state)
    }));
  }

  addUrl(id: string, url: string) {
    this.urls[id] = url;
  }

  findUrl(id: string) {
    return this.urls[id];
  }

  rowColor(state: State) {
    if (state === 'readyToMail') {
      return 'success';
    }

    return '';
  }

  btnColor(state: State) {
    if (state === 'readyToMail') {
      return 'btn-success';
    }

    return 'btn-info';
  }

  created() {
    this.pagination = {
      itemsPerPage: this.rowsPerPage,
      page: this.page,
      sortBy: ['invoiceNumber'],
      descending: true
    };
  }
}
