





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import uuid from 'uuid/v4';

@Component({
  inheritAttrs: false
})
export default class MonthPicker extends Vue {
  @Prop({ default: format(new Date(), 'yyyy-MM') }) value!: string;
  @Prop({ type: String }) label?: string;
  @Prop({ type: Function }) allowed?: any;
  @Prop({ type: String }) fieldName?: string;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: true }) clearable!: boolean;

  dialog: boolean = false;
  identifier: string = uuid();
  date: string | null = null;

  changed() {
    this.$emit('input', this.date);

    this.dialog = false;
  }

  @Watch('value')
  onValueChange(value: string) {
    this.date = value;
    // @ts-ignore
    this.$refs.date && this.$refs.date.validate();
  }

  created() {
    this.date = this.value;
    this.$emit('input', this.value);
  }

  delayed({ errors }: any) {
    if (errors.length) {
      return {
        on: ['input', 'change'],
        debounce: 350
      };
    }

    return {
      on: ['change'],
      debounce: 0
    };
  }
}
